
/*#region CSS Reset*/

body, img, p, form, a, div, li, input, textarea, h1, h2, h3, h4, h5, h6, table {
    margin: 0px;
    padding: 0px;
}

a, img, input, textarea {
    border: 0px;
    border-style: none;
}

a {
    text-decoration: none;
}

p {
    margin-bottom: 12px;
}

/*REMOVE THE OUTLINE BORDER WHEN CLICKING ON EN ELEMENT IN FF AND IE8*/
/*for FireFox*/
input[type="submit"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="image"]::-moz-focus-inner, img::-moz-focus-inner, a::-moz-focus-inner {
    border: 0px;
}
/*for IE8 */
input[type="submit"]:focus, input[type="button"]:focus, input[type="image"]:focus, img:focus, a:focus {
    outline: none;
}

/*ALWAYS DISPLAY VERTICAL SCROLLBAR*/
html {
    overflow: -moz-scrollbars-vertical;
    height: 100%;
}

html {
    overflow-x: hidden;
}

html {
    overflow-y: scroll;
}
body {
    min-height: 100%;
    /*background: url(/images/bg-img.png) repeat-y top center;
    background-size: cover;*/
    background:#ffffff;
}

/*REMOVE INPUT FIELDS BORDERS IN CHROME*/
*:focus {
    outline: none;
}

/*#endregion*/

/*#region General Classes*/

.center {
    margin: 0 auto;
}

.pointer {
    cursor: pointer;
}

.bold_over:hover {
    font-weight: bold;
}

.inline {
    display: inline;
}

.b {
    font-weight: bold;
}

.ta_center {
    text-align: center;
}

.d_none {
    display: none;
}

.v_hidden {
    visibility:hidden;
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.clearB {
    clear: both;
}

.floatR {
    float: right;
}

.floatL {
    float: left;
}

.mb20px {
    margin-bottom: 20px;
}

.mt20px {
    margin-top: 20px;
}

.dBlock {
    display: block;
}

.w100per {
    width: 100% !important;
}

.border_red {
    border: solid 1px red !important;
}

.align-right {
    text-align: right;
}

.bb0 {
    border-bottom: 0 !important;
}

.float-L {
    float: left;
}

.underline {
    text-decoration: underline;
}

.errorStyle, .selectboxit.selectboxit-enabled.selectboxit-btn.errorStyle {
    background-color: red !important;
}

.errorStyleBorder, input[type="checkbox"].errorStyleBorder + label::before, input[type="radio"].errorStyleBorder + label::before {
    box-shadow: 0 0 0 2px red;
}

.swal2-title{
    direction:rtl;
}

/*#endregion*/
/*#region General Definitions*/
.pages {   
    font: 16px 'Rubik', sans-serif;
    color: #333333;
    position: relative;
    text-align: right;
    direction: rtl;
}

.login {
    background: transparent linear-gradient(90deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
    /*background:#ffffff;*/
    min-height: 100%;
}

body .swal2-title {
    font: 30px 'Rubik', sans-serif;
}

body .swal2-html-container {
    font: 20px 'Rubik', sans-serif;
}

body .swal2-styled.swal2-confirm {
    background: #24286C;
    border: solid 3px #3478BE;
}

.container {
    width: 100%;
    max-width: 1455px;
    margin: 0 auto;
    position: relative;
}

h1, .h1, h1 a:active, h1 a:link, h1 a:visited, h1 a:hover {
    font-size: 60px;
    color: #333333;
    /*font-weight: 500;*/
    margin-bottom: 10px;
    padding: 0 5%;
    font-weight: bold;
}

h2, .h2, h2 a:active, h2 a:link, h2 a:visited, h2 a:hover {
    font-size: 40px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 10px;
}

h3, .h3, h3 a:active, h3 a:link, h3 a:visited, h3 a:hover {
    display: block;
    color: #333333;
    font-size: 22px;
    padding: 15px 0;
}

h4, .h4, h4 a:active, h4 a:link, h4 a:visited, h4 a:hover {
}

h5, .h5, h5 a:active, h5 a:link, h5 a:visited, h5 a:hover {
}

h6, .h6, h6 a:active, h6 a:link, h6 a:visited, h6 a:hover {
}

p, .p, p a:active, p a:link, p a:visited, p a:hover {
}

a:link {
    color: #333333;
}

a:visited {
    color: #333333;
}

a:hover {
    color: #333333;
}

a:active {
    color: #333333;
}

input {
}

select {
}

textarea {
}

fieldset {
    border: none;
}

legend {
    opacity: 0;
}
/*#endregion*/

/*#region header*/

header {
    height: 64px;
    background: transparent linear-gradient(90deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
}

.logo_h {
    height: 56px;
    margin-top: 4px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

.search {
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;
    width: 20%;
}

.search-btn {
    background: url(/images/search.png) no-repeat;
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align:middle;
    margin-right:5px;
}

.search-tb {
    display: inline-block;
    vertical-align:middle;
    width: calc(100% - 43px);
    background-color: #ffffff;
    border-bottom: solid 2px #6AC0F2;
    color: #24286C;
    font: 16px Rubik-Medium, Rubik Medium, Rubik, sans-serif;
    padding:0 5px;
    height:35px;
    box-sizing: border-box;
}

header ul.slimmenu li a {
    font: 18px 'Rubik', sans-serif;
}
/*#endregion*/
/*#region navigation*/
header ul.slimmenu {
    display: inline;
    vertical-align: middle;
}

    header ul.slimmenu li a {
        padding: 5px 20px;
        color: #ffffff;
    }

    header ul.slimmenu > li {
        border: none;
        background: none;
        margin-right: 0;
    }

/*#endregion*/

/*#region bead crumbs*/
.bread_crumbs span {
    color: #314988;
    font-weight: bold;
}

.bread_crumbs ul {
    display: inline-block;
    list-style: none;
    padding: 0;
}

    .bread_crumbs ul li {
        display: inline;
        text-decoration: underline;
        text-decoration-color: #314988;
        padding: 0 10px;
    }

        .bread_crumbs ul li a:link {
            color: #314988;
            font-weight: bold;
        }
/*#endregion*/

/*#region footer*/
    .top-footer {
        background: url(/images/footer-bg.png) no-repeat top left;
        background-size: 100% 30px;
        height: 30px;
        position: relative;
        bottom: -15px;
    }

    footer {
        background: transparent linear-gradient(90deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
        padding: 40px 0 40px 0;
        text-align: center;
        color: #ffffff;
    }

    footer a:link {
        color: #ffffff;
    }

    footer a:visited {
        color: #ffffff;
    }

    footer a:hover {
        color: #ffffff;
    }

    footer a:active {
        color: #ffffff;
    }

    footer h3 {
        padding: 0;
        font-size: 20px;
        font-weight: normal;
        color: #6AC0F2;
    }

    footer section {
        display: inline-block;
        vertical-align: top;
        text-align: right;
    }

    footer ul {
        display: inline-block;
        list-style: none;
        padding: 0 0 0 40px;
        margin-top: 5px;
        line-height: 26px;
        border-top: solid 1px #6AC0F2;
        margin-left:10px;
    }

    footer section.social ul li {
        display: inline-block;
        padding-left: 10px;
    }

    section.site-map {
        margin-top:23px;
    }
    footer .social ul
    {
        padding-top:10px;
    }
    footer .site-map 
    {
        margin-top:23px;
    }
/*#endregion*/
/*#region default*/
section.page-header-img {
    position: relative;
    text-align: center;
    margin-top: 10px;
    /*margin-bottom: 30px;*/
}
    section.page-header-img .main_img {
        position: relative;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: auto;
    }

    section.page-header-img .logo {
        position: absolute;
        max-width: 400px;
        width: 30%;
        top: 6%;
        right: 50px;
        z-index: 10;
    }
        section.page-header-img .logo.fix {
            top: 6.8%;
        }
        section.page-header-img .page-header-img-txt {
            position: absolute;
            top: 24%;
            z-index: 10;
            right: 50px;
            text-align: center;
            color: #ffffff;
            width: 40%;
            max-width: 408px;
            font-size: 36px;
        }
            section.page-header-img .page-header-img-txt.fix {
                top: 27.1%;
            }
        .page-header-img-txt h3 {
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 20px;
            color: #ffffff;
            line-height: 33px;
        }
        .styled1 {
        color: #73D0F8;
        font-size: 30px;
        }
.styled2 {
    color: #ffffff;
    font-size: 32px;
    font-weight:bold;
}


.page-header-img-txt h3 b {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
}

.page-header-img-txt h4 {
    font-size: 40px;
    font-weight: normal;
    line-height: 55px;
}

section.contact {
    margin-bottom: 50px;
    margin-top:50px;
}
section.events h1, section.contact h1 {
    font-size: 50px;
    font-weight: normal;
    text-align: center;
    background: transparent linear-gradient(270deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.txt-container
{
    background:#ffffff;
    color:#333333;
    padding:40px;
    font-size:22px;
}
section.events h2 {
    background: transparent linear-gradient(270deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
    font-size: 30px;
    color: #ffffff;
    padding: 3px 0;
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
}

.events-courses {
    display: inline-block;
    width: calc(50% - 20px);
    margin-left: 40px;
    vertical-align: top;
}

.events-workshop {
    display: inline-block;
    width: calc(50% - 20px);
    vertical-align: top;
}

.events-courses-box {
    border: solid 1px #d7d7d7;
    background: #ffffff;
    height: 264px;
    margin-bottom: 30px;
    color: #24286C;
    position: relative;
    box-sizing: border-box;
    box-shadow: -5px 7px 10px -5px #636363;
}
.time-place-info.no_img_fix {
    width:100%;
}
.img-contaner {
    position: relative;
    float: right;
    display: inline-block;
}

.events-courses-box-img {
    display: block;       
    height: auto;
    max-width:348px;
}
.closed-courses
{
    display:block;
    background:#24286C;
    color:#ffffff;
    text-align:center;
    padding:10px;
    box-sizing:border-box;
    font-size:22px;
    font-weight:bold;
    position:absolute;
    top: calc(50% - 23px);
    width:100%;
}
.coursesInfo {
    /*border-top: solid 1px #24286C;*/
    padding: 10px 0;
    margin-top: 5px;
    border-bottom: solid 1px #24286C;
}
.coursesTime {
    display:block;
    border-bottom: solid 1px #24286C;
    height:60px;
}
.coursesInfo:last-child {
    border-top: none;
}

    .coursesInfo > span {
        border-left: solid 1px #24286C;
        padding-left: 10px;
    }

    .coursesInfo.place {
        padding: 20px 0;
    }

.smallFont {
    font-size: 16px;
    padding: 20px 0 20px 20px;
}

.width300px.bottons-container {
    /*width: 300px;*/
    padding: 0 20px 20px 0px;
    box-sizing: border-box;
    margin: 1px;
}

.events-courses-box-full {
    height: auto;
}

.events-courses-box .time-place-info span:last-child {
    border: none;
}
.events-courses-box .time-place-info span.date.last
{
    margin-left:0;
}
.events-courses-box .time-place-info {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 350px);
    line-height: 24px;
    position: relative;
    box-sizing:border-box;
    padding:0 15px;
}

.events-courses-box .reg-btn-wrapper {
    display: inline-block;
    vertical-align: top;
    width: calc(45% - 126px);
    margin-right: 20px;
    padding-top: 15px;
    line-height: 24px;
}

section .events-courses-box h2 {
    font-size: 26px;
    font-weight: bold;
    border-bottom: none;
    margin-bottom: 10px;
    text-align: right;
    margin-top: 15px;
    background: none;
    color: #24286C;
    height:50px;
    overflow:hidden;
}
.generalInfo
{
    height:75px;
    overflow:hidden;
}
.events-courses-box .time-place-info span {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.categoryPage-header-ttl {
   /* background: transparent linear-gradient(180deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;*/
   background:#ffffff;
    /*margin-bottom: 20px;*/
    margin-top: 10px;
}

.cat-img {
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
}

.cat-txt {
    display: inline-block;
    vertical-align: top;
    color: #242670;
    width: calc(100% - 430px);
    margin: 20px 0;
    padding-bottom: 80px;
    margin-right: 20px;
    font-weight: bold;
    position: relative;
}

.cat-ttl {
    color: #ffffff;
    padding: 10px 0;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    background: transparent linear-gradient(270deg, #089D6B 0%, #044F36 100%) 0% 0% no-repeat padding-box;
}

.big-num {
    font-size: 20px;
    letter-spacing: 2px;
}

a.more-info-btn {
    color: #24286C;
    font-weight: bold;
    background: url(/images/more-info-btn.png) no-repeat center left;
    padding-left: 40px;
    display: inline-block;
    height: 30px;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
}

.more-info-btn img {
    width: 20px;
    margin-left: 4px;
}

.course-reg-btn {
    display: inline-block;
    width: 152px;
    background: transparent linear-gradient(180deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;
    height: 50px;
    text-align: center;
    color: #ffffff;
    margin-top: 12px;
    cursor: pointer;
    font: 18px 'Rubik', sans-serif;
    /*position: absolute;
    left: 15px;*/
    float: left;
}

    .course-reg-btn.course-full {
        background: transparent linear-gradient(180deg, #AFAFAF 0%, #AFAFAF 100%) 0% 0% no-repeat padding-box;
        cursor:default;
    }

.events-courses-box.workshops {
    height: auto;
    padding-bottom: 20px;
}

.workshops .events-courses-box-img {
    display: block;
    float: none;
    margin-bottom: 15px;
    width: 100%;
    height: auto;
    margin-left: 0;
    max-width: none;
}

.events-courses-box.workshops .time-place-info {
    width: 100%;
    display: block;
    padding: 0 20px;
    box-sizing: border-box;
}

section.events-workshop .events-courses-box h2 {
    margin-bottom: 10px;
}

section .events-courses-box h2 span.type {
    font-weight: normal;
    float: left;
}

section.events-workshop .more-info-btn {
    margin-right: 20px;
}

a.generall-btn-style {
    border: solid 1px #3478BE;
    color: #314988;
    display: inline-block;
    padding: 10px 20px 10px 50px;
    font-weight: bold;
    margin-top: 10px;
    background: url(/images/more-info-btn.png) no-repeat 10px center;
}

.engineers_hook .events-courses-box {
    border-top: solid 3px #317454;
}

.engineers_hook section .events-courses-box h2 {
    color: #317454;
}

.engineers_hook .events-courses-cat-name {
    color: #317454;
}

.bread_crumbs {
    margin-right: 15px;
}

.syllabusTitle{
    font-size:16px;
}

.more-info-container {
    padding: 0;
}
.categoryPage-read-more
{
    display:block;
    width:197px;
    height:48px;
    color:#ffffff !important;
    text-align:center;
    box-sizing:border-box;
    padding-top:14px;
    padding-left:20px;
    border:solid 1px #ffffff;
    cursor:pointer;
    position:absolute;
    bottom:20px;
    right:0;
}
.readMoreTextContainer{
    display:none;
    opacity:0;
}
    .readMoreTextContainer a
    {
        color:#ffffff !important;
    }
    /*#endregion*/
    /*#region Login*/
    .login {
        /*background: transparent linear-gradient(90deg, #3478BE 0%, #24286C 100%) 0% 0% no-repeat padding-box;*/
        background:#ffffff;
        min-height: 100%;
        position: relative;
        font: 16px 'Rubik', sans-serif;
        direction: rtl;
    }

.login-img-right {
    position: absolute;
    top: 0;
    left: 0;
    max-width:600px;
}

.login .container {
    max-width: 576px;
    width: 90%;
    margin: 0 auto;
    padding-top: 166px;
    color: #242670;
    font: 16px 'Rubik', sans-serif;
    text-align: right;
}

.tb-container {
    display: inline-block;
    width: calc(50% - 10px);
    margin-left: 14px;
}

    .tb-container.code {
        display: block;
        margin: 10px auto 0 auto;
    }

    .tb-container.last {
        margin-left: 0;
    }

.tb {
    border: 1px solid #187BC1;
    background: none;
    height: 40px;
    width: 100%;
    color: #242670;
    font: 16px 'Rubik', sans-serif;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 20px;
}

.login-btn {
    display: block;
    width: 240px;
    height: 40px;
    margin: 40px auto;
    cursor: pointer;
    color: #242670;
    font: bold 20px 'Rubik', sans-serif;
    background: #ffffff;
    border: 1px solid #ce36b1;
    border-radius: 20px;
    background: #ffffff url(/images/btn_arrow.png) no-repeat 35px center;
}

.login .container .logo {
    width: 100%;
    margin-bottom: 50px;
    position: relative;
    right: -85px;
}

.login-img-left {
    position: absolute;
    top: calc(50% - 156px);
    left: 0;
}

.bottom-footer {
    height: 50px;
    background: #ffffff;
}
/*#endregion*/

/*#region hook*/

/*administrative_assistant_hook*/

.administrative_assistant_hook .categoryPage-read-more {
    background: #498FE0 url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}
.administrative_assistant_hook .cat-ttl {
    background: #498FE0;
}
.administrative_assistant_hook .events-courses-box {
    border-top: solid 4px #498FE0;
}
.administrative_assistant_hook section .events-courses-box h2 {
    color: #498FE0;
}
.administrative_assistant_hook section .events-courses-cat-name {
    color: #498FE0;
}
.administrative_assistant_hook .bread_crumbs ul li a.selected {
    background: #498FE0 !important;
    color: #ffffff !important;
}

/*project_management_hook*/
.project_management_hook .categoryPage-read-more {
    background: #00ACBF url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.project_management_hook .cat-ttl {
    background: #00ACBF;
}

.project_management_hook .events-courses-box {
    border-top: solid 4px #00ACBF;
}

.project_management_hook section .events-courses-box h2 {
    color: #00ACBF;
}

.project_management_hook section .events-courses-cat-name {
    color: #00ACBF;
}

.project_management_hook .bread_crumbs ul li a.selected {
    background: #00ACBF;
    color: #ffffff !important;
}

/*managers_hook*/
.managers_hook .categoryPage-read-more {
    background: #00ACBF url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.managers_hook .cat-ttl {
    background: #00ACBF;
}

.managers_hook .events-courses-box {
    border-top: solid 4px #00ACBF;
}

.managers_hook section .events-courses-box h2 {
    color: #00ACBF;
}

.managers_hook section .events-courses-cat-name {
    color: #00ACBF;
}

.managers_hook .bread_crumbs ul li a.selected {
    background: #00ACBF;
    color: #ffffff !important;
}

/*engineers_hook*/
.engineers_hook .categoryPage-header-ttl {
    border: solid 1px #6C854E;
}
.engineers_hook .categoryPage-read-more {
    background: #789A57 url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.engineers_hook .cat-ttl {
    background: linear-gradient(to right, #3F5D22, #789A57, #789A57);
}

.engineers_hook .events-courses-box {
    border-top: solid 4px #789A57;
}

.engineers_hook section .events-courses-box h2 {
    color: #789A57;
}

.engineers_hook section .events-courses-cat-name {
    color: #789A57;
}

.engineers_hook .bread_crumbs ul li a.selected {
    background: #789A57;
    color: #ffffff !important;
}

/*economists_hook*/
.economists_hook .categoryPage-read-more {
    background: #657D91 url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.economists_hook .cat-ttl {
    background: #657D91;
}

.economists_hook .events-courses-box {
    border-top: solid 4px #657D91;
}

.economists_hook section .events-courses-box h2 {
    color: #657D91;
}

.economists_hook section .events-courses-cat-name {
    color: #657D91;
}

.economists_hook .bread_crumbs ul li a.selected {
    background: #657D91;
    color: #ffffff !important;
}

/*live_hook*/
.live_hook .categoryPage-header-ttl {
    border: solid 1px #171160;
}
.live_hook .categoryPage-read-more {
    background: #1F0280 url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.live_hook .cat-ttl {
    background: linear-gradient(to right, #1F0280, #100140, #100140);
}

.live_hook .events-courses-box {
    border-top: solid 4px #1F0280;
}

.live_hook section .events-courses-box h2 {
    color: #1F0280;
}

.live_hook section .events-courses-cat-name {
    color: #1F0280;
}

.live_hook .bread_crumbs ul li a.selected {
    background: #1F0280;
    color: #ffffff !important;
}

/*powerskills hook*/
.powerskills_hook .categoryPage-header-ttl {
    border: solid 1px #A37026;
}
.powerskills_hook .categoryPage-read-more {
    background: #D9960B url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.powerskills_hook .cat-ttl {
    background: #D9960B;
}

.powerskills_hook .events-courses-box {
    border-top: solid 4px #D9960B;
}

.powerskills_hook section .events-courses-box h2 {
    color: #D9960B;
}

.powerskills_hook section .events-courses-cat-name {
    color: #D9960B;
}

.powerskills_hook .bread_crumbs ul li a.selected {
    background: #D9960B;
    color: #ffffff !important;
}

/*newworld hook*/
.newworld_hook .categoryPage-read-more {
    background: #1187C8 url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.newworld_hook .cat-ttl {
    background: #1187C8;
}

.newworld_hook .events-courses-box {
    border-top: solid 4px #1187C8;
}

.newworld_hook section .events-courses-box h2 {
    color: #1187C8;
}

.newworld_hook section .events-courses-cat-name {
    color: #1187C8;
}
.newworld_hook .bread_crumbs ul li a.selected {
    background: #1187C8;
    color: #ffffff !important;
}

/*interpersonalskills hook*/
.interpersonalskills_hook .categoryPage-header-ttl {
    border: solid 1px #337A87;
}
.interpersonalskills_hook .categoryPage-read-more {
    background: #47B97C url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.interpersonalskills_hook .cat-ttl {
    background: linear-gradient(to right, #245e3e, #47b97c, #47b97c);
}

.interpersonalskills_hook .events-courses-box {
    border-top: solid 4px #47B97C;
}

.interpersonalskills_hook section .events-courses-box h2 {
    color: #47B97C;
}

.interpersonalskills_hook section .events-courses-cat-name {
    color: #47B97C;
}

.interpersonalskills_hook .bread_crumbs ul li a.selected {
    background: #47B97C;
    color: #ffffff !important;
}


/*selfmanagement hook*/
.selfmanagement_hook .categoryPage-header-ttl {
    border: solid 1px #7F2C68;
}
.selfmanagement_hook .categoryPage-read-more {
    background: #90246F url(/images/next.png) no-repeat;
    background-position-x: 20px;
    background-position-y: center;
}

.selfmanagement_hook .cat-ttl {
    background: #90246F;
}

.selfmanagement_hook .events-courses-box {
    border-top: solid 4px #90246F;
}

.selfmanagement_hook section .events-courses-box h2 {
    color: #90246F;
}

.selfmanagement_hook section .events-courses-cat-name {
    color: #90246F;
}

.selfmanagement_hook .bread_crumbs ul li a.selected {
    background: #90246F;
    color: #ffffff !important;
}

/*#endregion*/
/*#endregion*/
.main_img-m {
    display: none;
}

.contact-container {
    color: #ffffff;
}
@media all and (max-width: 1700px) {
    .login-img-right {
        display: block;
        float:left;
        width:50%;
        max-width: 550px;
        position: static;
    }
}
    @media all and (max-width: 1450px) {
        .events-courses, .events-workshop {
            width: 90%;
            margin: 0 auto 60px auto;
            display: block;
        }

        a.generall-btn-style {
            margin: 20px auto;
            display: block;
            text-align: center;
            max-width: 220px;
        }
    }

    @media all and (max-width: 1200px) {
        .login-img-left {
            display: none;
        }

        /*.login-img-right {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 550px;
        position: static;
    }*/

        .page-header-img-txt h3 {
            font-size: 18px;
        }

            .page-header-img-txt h3 b {
                font-size: 22px;
            }

        section.page-header-img .logo {
            top: 8%;
        }

        section.page-header-img .page-header-img-txt {
            top: 38%;
            width: 40%;
            right: 10px;
        }
    }

    @media all and (max-width: 900px) {
        .events-courses-box-img {
            width: 100%;
            display: block;
            max-width: 350px;
            float: none;
            margin: 10px auto;
        }

        .workshops .events-courses-box-img {
            margin-top: 0;
        }

        .events-courses-box .time-place-info {
            display: block;
            margin: 0 auto;
            width: 90%;
        }

        .events-courses-box {
            height: auto;
            padding-bottom: 20px;
        }

        /*section .events-courses-box h2 {
        text-align: center;
    }*/

        .bottons-container {
            width: 90%;
            margin: 0 auto;
        }

        .course-reg-btn {
            left: 5%;
        }
    }

@media all and (max-width: 800px) {
    /*.text-mb {
        margin-bottom: 47px !important;
    }*/
    body
    {
        background:#ffffff;
    }
    .login .container .logo
    {
        width:45%;
        position:static;
        right:0;
        margin-top:-30px;
    }
    .categoryPage-read-more {
        bottom: -10px;
        right: 30px;
    }

        .readMoreTextContainer {
            padding-bottom: 30px;
        }

        .categoryPage-header-ttl {
            padding-bottom: 30px;
        }

        .img-contaner {
            float: none;
            display: block;
        }

        .bread_crumbs {
            background: #ffffff;
            margin-right: 0;
            padding: 5px 10px;
        }
        /*  .bread_crumbs ul li a:link, .bread_crumbs ul li a:visited, .bread_crumbs ul li a:hover, .bread_crumbs ul li a:active {
            text-align: right;
            color: #ffffff;
        }
        .bread_crumbs span
        {
            color:#ffffff;
        }*/
        .cat-ttl {
            font-size: 26px;
        }

        section.page-header-img {
            margin-top: 0;
        }

        header .menu-collapser {
            background: none;
            width: auto;
        }

        header .collapse-button {
            padding: 0;
            background: none !important;
            border: none;
            box-shadow: none;
            margin-top: 10px;
            width: 40px;
        }

        header .slimmenu.collapsed {
            background: #73d0f8;
            position: absolute;
            top: 70px;
            z-index: 10000;
        }

        header .collapse-button .icon-bar {
            width: 40px;
            height: 4px;
            margin-bottom: 5px;
            margin-right: 10px;
        }

        header ul.slimmenu.collapsed li a {
            padding: 10px;
        }

            header ul.slimmenu.collapsed li a:hover {
                background: #24286C;
                color: #ffffff;
            }

        .events-courses-box {
            height: auto;
            min-height: 290px;
        }

        .page-header-img-txt h3 {
            font-size: 18px;
        }

            .page-header-img-txt h3 b {
                font-size: 22px;
            }

        .search {
            margin-right: 20px;
        }

        /*.main_img-m {
        display: block;
        width: 88% !important;
        margin: 0 auto 10px auto;
    }*/
        .main_img-m2 {
            display: block;
            width: 100%;
            margin: 29px auto 0 auto;
        }

        .main_img {
            display: none !important;
        }

        /*.page-header-img {
        background: transparent linear-gradient(90deg, #24286C 0%, #3478BE 100%) 0% 0% no-repeat padding-box;*/
        /*padding: 40px 10px;*/
        /*padding: 0 0px;
    }*/

        section.page-header-img .logo, section.page-header-img .page-header-img-txt {
            position: static;
            width: 90%;
            max-width: none;
            margin: 0 auto;
        }

        section.page-header-img .logo {
            max-width: 300px;
            margin: 30px 0;
        }

        .page-header-img-txt h3 {
            font-size: 26px;
        }

        header {
            background: #73d0f8;
        }

        .logo_h {
            float: left;
            margin-left: 10px;
            margin-top: 5px;
            width: 110px;
            height: auto;
        }

        .search {
            width: 200px;
            display: none;
        }

        /*.cat-img {
        width: 100px;
        margin-top: -70px;
        margin-bottom: 0;
    }*/

        .cat-ttl {
            box-sizing: border-box;
            padding-right: 130px;
            text-align: right;
        }

        .cat-txt {
            width: 100%;
            box-sizing: border-box;
            padding: 10px 30px 30px 30px;
            margin: 0;
        }

        footer .container {
            width: 90%;
            text-align: center;
        }
    }

    @media all and (max-width: 520px) {
        .tb-container {
            width: 100%;
            margin-bottom: 20px;
        }

        .login .container {
            padding-top: 50px;
        }

        .coursesInfo span.date {
            width: calc(50% - 14px);
            margin: 0;
            display: inline-block;
            padding: 0 5px;
        }

            .coursesInfo span.date.last {
                border-left: none;
            }

        .coursesInfo span.hour {
            width: 100%;
            padding: 0 5px;
            margin: 0;
            margin-top: 10px;
            padding-top: 10px;
            border-top: solid 1px #24286C !important;
        }

        a.more-info-btn {
            width: 90px;
            text-align: center;
        }

        footer section {
            text-align: center;
            width: 100%;
        }

        footer ul {
            padding: 0;
            width: 100%;
        }
    }

    .course-min-height {
        min-height: 200px;
        /*border-bottom: solid 1px #24286C;*/
    }


